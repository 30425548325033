<!-- 首页 -->
<template>
	<div class="shouYe" id="shouYe">
		<!-- 轮播图 -->
		<div class="page-header-banner">
			<el-carousel>
				<el-carousel-item v-for="(item,index) in bannerList" :key="index">
					<h-img class="pic-box pic-box-1" :src="isMobile ? item.mobileBannerImg: item.bannerImg"></h-img>
				</el-carousel-item>
			</el-carousel>
		</div>
		<!-- 主体 -->
		<div class="page-body-content">
			<!-- 公司简介 -->
			<div class="company-introduction ">
				<div class="company-introduction-mt">
					<div class="left-block">
						<div class="header">
							<div class="title">公司简介</div>
							<div class="english">Company Profile</div>
						</div>
						<div class="body textAlignJustify">{{configListAll.companyDesc}}</div>
						<div v-if="!isMobile" class="right-block wow">
							<div class="footer">
								<div class="item">
									<div class="container">
										<div class="txt">客户数量</div>
										<div v-if="!isNumberScroll" class="num">0</div>
										<div v-else class="num">
											<CountTo
											  :startVal='0'
											  :endVal='numberFormate(configListAll.keHuShuLiang)'
											  :duration='3000'
											/>
											<div>{{configListAll.keHuShuLiang | getUnitFromValue}}</div>
										</div>
									</div>
								</div>
								<div class="item">
									<div class="container">
										<div class="txt">覆盖国家和地区</div>
										<div v-if="!isNumberScroll" class="num">0</div>
										<div v-else class="num">
											<CountTo
											  :startVal='0'
											  :endVal='numberFormate(configListAll.fuGaiGuoJia)'
											  :duration='3000'
											/>
											<div>{{configListAll.fuGaiGuoJia | getUnitFromValue}}</div>
										</div>
									</div>
								</div>
								<div class="item">
									<div class="container">
										<div class="txt">合作运营商</div>
										<div v-if="!isNumberScroll" class="num">0</div>
										<div v-else class="num">
											<CountTo
											  :startVal='0'
											  :endVal='numberFormate(configListAll.heZuoYunYingShang)'
											  :duration='3000'
											/>
											<div>{{configListAll.heZuoYunYingShang | getUnitFromValue}}</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
					<div class="pic-box">
						<h-img class="pic-005" :src="configListAll.companyImg"></h-img>
					</div>
					<div v-if="isMobile" class="right-block">
						<div class="footer">
							<div class="item">
								<div class="container">
									<div class="txt">客户数量</div>
									<!-- <CountTo
									  :startVal='0'
									  :endVal='233'
									  :duration='3000'
									/> -->
									<div class="num">{{configListAll.keHuShuLiang}}</div>
								</div>
							</div>
							<div class="item">
								<div class="container">
									<div class="txt">覆盖国家和地区</div>
									<div class="num">{{configListAll.fuGaiGuoJia}}</div>
								</div>
							</div>
							<div class="item">
								<div class="container">
									<div class="txt">合作运营商</div>
									<div class="num">{{configListAll.heZuoYunYingShang}}</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
			<!-- 产品与服务 -->
			<div class="production-service wow animate__animated animate__bounceInUp">
				<div class="production-service-container">
					<div class="header">
						<div class="left-block">
							<div class="title">产品与服务</div>
							<div class="english">Products and services</div>
						</div>
						<div class="more-box" @click="$router.push('chanPinFuWu')">
							<div class="txt">更多</div>
							<div class="icon"></div>
						</div>
					</div>
					<div class="nav-list">
						<div class="item item-1" v-for="(item,index) in chanPinFuWu" :key="index"
							@click="chanPinFuWu_item_click(item,index)">
							<h-img class="bg" :src="item.productBackgroudImg"></h-img>
							<div class="cover-box">
								<h-img class="pic-008" :src="item.productLogo"></h-img>
								<div class="title">{{item.productTitle}}</div>
								<div class="description">{{item.productSubtitle}}</div>
							</div>
						</div>
						<!-- <div class="item item-2">
							<img class="pic-004" src="@/assets/004.png" alt="">
							<div class="title">话费业务</div>
							<div class="description">一站式通讯云服务</div>
						</div>
						<div class="item item-3">
							<img class="pic-007" src="@/assets/007.png" alt="">
							<div class="title">流量业务</div>
							<div class="description">一站式通讯云服务</div>
						</div>
						<div class="item item-4">
							<img class="pic-009" src="@/assets/009.png" alt="">
							<div class="title">油卡业务</div>
							<div class="description">一站式通讯云服务</div>
						</div> -->
					</div>
				</div>
			</div>
			<!-- 我们的优势 -->
			<div class="our-advantage wow animate__animated animate__bounceInUp">
				<div class="our-advantage-container">
					<div class="header">
						<div class="left-block">
							<div class="title">我们的优势</div>
							<div class="english">Our advantages</div>
						</div>
						<!-- <div class="more-box">
							<div class="txt">更多</div>
							<img class="pic-006" src="@/assets/006.png" alt="">
						</div> -->
					</div>
					<div class="body">
						<div class="list">
							<div class="item item-1" v-for="(item,index) in woMenDeYouShi" :key="index">
								<div class="content-box">
									<div class="title">{{item.contentTitle}}</div>
									<div class="description">{{item.contentDescription}}</div>
									<!-- <div class="content"
										v-html="item.contentDetails && formatRichText(item.contentDetails)"></div> -->
									<div class="content">{{item.contentDescription}}</div>
								</div>
								<div class="hover-box">
									<h-img class="bg" :src="item.contentImg"></h-img>
									<div class="bottom-box">
										<div class="title">{{item.contentTitle}}</div>
										<div class="description">{{item.contentDescription}}</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
			<!-- 新闻中心 -->
			<div class="news-center wow animate__animated animate__bounceInUp">
				<div class="news-center-container">
					<div class="header">
						<div class="left-block">
							<div class="title">新闻中心</div>
							<div class="english">News Center</div>
						</div>
						<div class="nav-list">
							<div class="item"
								:class="menuItem_xinWenZhongXin_item.menuId == menuItem_xinWenZhongXin_active_menuId ? 'active':'normal'"
								v-for="(menuItem_xinWenZhongXin_item,index) in menuItem_xinWenZhongXin.children"
								:key="index"
								@click="menuItem_xinWenZhongXin_item_click(menuItem_xinWenZhongXin_item,index)">
								{{menuItem_xinWenZhongXin_item.menuName}}
							</div>
							<!-- <div class="item normal">公司动态</div>
							<div class="item normal">产品动态</div>
							<div class="item normal">行业新闻</div>
							<div class="item normal">黑名单公示</div> -->
						</div>
						<div class="more-box" @click="xinWenZhongXin_more_click">
							<div class="txt">更多</div>
							<div class="icon"></div>
						</div>
					</div>
					<div class="body">
						<div class="banner-block">
							<el-carousel direction="vertical">
								<el-carousel-item v-if="index >= 0 && index <= 2" v-for="(item,index) in xinWenZhongXin"
									:key="index">
									<div class="content"
										@click="$router.push({name: 'xinWenXiangQing',params: {id: item.id}})">
										<h-img class="pic-003" :src="item.contentImg"></h-img>
										<div class="cover-box">
											<div class="bottom-box">
												<div class="title">{{item.contentTitle}}</div>
												<div class="description">发布时间：{{getDateTime(item.createTime)}}</div>
											</div>
										</div>
									</div>
								</el-carousel-item>
							</el-carousel>
						</div>
						<div class="list">
							<div class="item" v-if="index >= 3 && index <= 6" v-for="(item,index) in xinWenZhongXin"
								:key="index" @click="$router.push({name: 'xinWenXiangQing',params: {id: item.id}})">
								<div class="title">{{item.contentTitle}}</div>
								<div class="time">发布时间：{{getDateTime(item.createTime)}}</div>
								<div class="pic-box">
									<img class="pic-037" src="@/assets/037.png" alt="">
									<img class="pic-038" src="@/assets/038.png" alt="">
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
			<!-- 荣誉展示 -->
			<div class="honor-display wow animate__animated animate__bounceInUp">
				<div class="honor-display-container">
					<div class="header">
						<div class="left-block">
							<div class="title">荣誉展示</div>
							<div class="english">Honorary display</div>
						</div>
						<div class="more-box" @click="rongYuZhanShi_more_click">
							<div class="txt">更多</div>
							<div class="icon"></div>
						</div>
					</div>
					<div class="list">
						<el-carousel>
							<el-carousel-item v-if="index < 3" v-for="(item,index) in rongYuZhanShi" :key="index">
								<div class="content" v-for="(subitem,subindex) in item" :key="subindex">
									<h-img style="object-fit: contain;" class="pic-certificate"
										:src="subitem.contentImg"></h-img>
								</div>
							</el-carousel-item>
						</el-carousel>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	import {
		mapState
	} from 'vuex'
	import {
		BannerList
	} from '@/apis/lunBoTu.js'
	import {
		ContentPage,
		ContentList
	} from '@/apis/wenZhangLanMu.js'
	import {
		MenuList
	} from '@/apis/caiDan.js'
	import {
		ProductList
	} from '@/apis/chanPinFuWu.js'
	export default {
		data() {
			return {
				bannerList: [],
				chanPinFuWu: [],
				woMenDeYouShi: [],
				xinWenZhongXin: [],
				menuItem_xinWenZhongXin: {},
				menuItem_xinWenZhongXin_active_menuId: '',
				rongYuZhanShi: [],
				isNumberScroll: false
				
			};
		},
		computed: {
			...mapState(['configListAll'])
		},
		filters: {
			getUnitFromValue(val) {
				 var num = parseFloat(val)
				 return val.replace(num, '').replace(/\./, '');
			 }
		},
		created() {
			this.getBannerList()
			this.getProductList()
			this.getContentPageForWoMenDeYouShi()
			this.getMenuListForXinWenZhongXin()
			this.getContentListForRongYuZhanShi()
			
		},
		mounted() {
			new WOW({
				boxClass: 'wow',
				animateClass: 'animated',
				offset: this.isMobile ? 0 : 150,
				mobile: true,
				live: false
			}).init();
			
			// console.log('111: ',111);
			
			// console.log('222: ',$('.right-block'));
			let numberScroll = setInterval(() => {
				if ($('.right-block').hasClass("animated")) {
					// console.log('333: ',333);
					this.isNumberScroll = true
					console.log('this.isNumberScroll: ',this.isNumberScroll);
					clearInterval(numberScroll)
				}
			},1000)
		},
		methods: {
			rongYuZhanShi_more_click() {
				this.$router.push({
					name: 'guanYuWoMen'
				})
				let find_item = this.$store.getters.getMenuItem('guanYuWoMen').children.find(val => val.menuName == '公司荣誉')
				this.$store.commit('changeErJiMenuPath', find_item.path)
			},
			xinWenZhongXin_more_click() {
				this.$router.push({
					name: 'xinWenZhongXin'
				})
				let find_item = this.menuItem_xinWenZhongXin.children.find(val => val.menuId == this
					.menuItem_xinWenZhongXin_active_menuId)
				find_item.path && this.$store.commit('changeErJiMenuPath', find_item.path)
			},
			chanPinFuWu_item_click(item, index) {
				this.$router.push({
					name: 'chanPinFuWu'
				})
				this.$store.commit('changeErJiMenuPath', item.productTitle)
			},
			getBannerList() {
				BannerList().then(res => {
					this.bannerList = res.data
				})
			},
			getProductList() {
				ProductList().then(res => {
					this.chanPinFuWu = res.data
				})
			},
			getContentPageForWoMenDeYouShi() {
				ContentPage('', 2106).then(res => {
					this.woMenDeYouShi = res.data
				})
			},
			getMenuListForXinWenZhongXin() {
				this.menuItem_xinWenZhongXin = {
					...this.$store.getters.getMenuItem('xinWenZhongXin')
				}
				console.log("this.menuItem_xinWenZhongXin: ", this.menuItem_xinWenZhongXin);

				this.menuItem_xinWenZhongXin.children = [{
						menuName: '全部',
						menuId: this.menuItem_xinWenZhongXin.menuId
					},
					...this.menuItem_xinWenZhongXin.children
				]

				this.menuItem_xinWenZhongXin_active_menuId = this.menuItem_xinWenZhongXin.menuId

				this.getContentPageForXinWenZhongXin()
			},
			getContentPageForXinWenZhongXin() {
				if (this.menuItem_xinWenZhongXin_active_menuId == this.menuItem_xinWenZhongXin.menuId) {
					ContentPage('', this.menuItem_xinWenZhongXin_active_menuId, 1, 7).then(res => {
						this.xinWenZhongXin = res.data
					})
				} else {
					ContentPage(this.menuItem_xinWenZhongXin_active_menuId, '', 1, 7).then(res => {
						this.xinWenZhongXin = res.data
					})
				}
			},
			menuItem_xinWenZhongXin_item_click(item, index) {
				if (this.menuItem_xinWenZhongXin_active_menuId == item.menuId) {
					return
				} else {
					this.menuItem_xinWenZhongXin_active_menuId = item.menuId
					this.getContentPageForXinWenZhongXin()
				}
			},
			getContentListForRongYuZhanShi() {
				ContentList(2101, '').then(res => {
					if (!this.isMobile) {
						this.rongYuZhanShi = DynamicArr(res.data, 3)
					} else {
						this.rongYuZhanShi = DynamicArr(res.data, 1)
					}

					function DynamicArr(arr, size) {
						let res = []
						let count = Math.ceil(arr.length / size)
						arr.forEach((val, index) => {
							if ((index + 1) % size == 0) {
								res.push(arr.slice(index - size + 1, index + 1))
							}
						})
						return res
					}
				})
			},

		}
	};
</script>

<style lang="scss" scoped>
	@import './shouYe.scss';
	@import './shouYeMedia.scss';
</style>

