// 轮播图
import request from '@/util/request.js'

// 轮播图
export function BannerList() {
	return request({
		method: 'post',
		url: `/api/banner/list`
	})
}
